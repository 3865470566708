import { AnimationTriggerType } from '@/modules/editor/modules/settings/components/controls/animation/types';
import type { InteractionTriggerEvent, TargetEventOption } from '../types';

export enum TriggerEvent {
  EMPTY = '',
  CLICK = 'click',
  ACTIVE = 'active',
  MOUSE_OVER = 'mouseover',
  MOUSE_ENTER = 'mouseenter',
  APPEAR = 'gp:appear',
  ITEM_SELECTED = 'gp:item-selected',
  ITEM_UNSELECTED = 'gp:item-unselected',
  ITEM_HOVERED = 'gp:item-hovered',
  ITEM_CLICK = 'gp:item-click',
  AFTER_SUBMIT = 'gp:after-submit',
  QUANTITY_CHANGE = 'gp:quantity-change',
  AFTER_CHECKED_VALUE = 'gp:after-checked-value',
  AFTER_ADD_TO_CART = 'gp:after-add-to-cart',
  POPUP_OPEN = 'gp:popup-open',
  POPUP_CLOSE = 'gp:popup-close',
  SCROLL_UP = 'gp:scroll-up',
  SCROLL_DOWN = 'gp:scroll-down',
  DISTANCE = 'gp:distance',
  DELAY = 'gp:delay',
  VARIANT_HOVERED = 'gp:variant-hovered',
  VARIANT_SELECTED = 'gp:variant-selected',
  COMPARE_PRICE_CHANGE = 'gp:compare-price-change',
  PRICE_CHANGE = 'gp:price-change',
  STOCK_CHANGE = 'gp:stock-change',
  EXPAND_ITEM = 'gp:expand-item',
  OPEN_POPUP = 'gp:popup-open',
  CLOSE_POPUP = 'gp:popup-close',
  REMAINING_TIME = 'gp:countdown-timer',
  OUT_OF_STOCK = 'gp:out-of-stock',
  FINISH_LOADING = 'finish-loading',
  TAB_ACTIVE = 'gp:tab-active',
  SELECT_SLIDE = 'gp:select-slide',
}

export enum ControlType {
  CLICK = 'click',
  MOUSE_OVER = 'mouseover',
  MOUSE_ENTER = 'mouseenter',
  ITEM_SELECTED = 'gp:item-selected', // Tabs, Carousel
  VARIANT_HOVERED = 'gp:variant-hovered',
  VARIANT_CLICKED = 'gp:variant-clicked',
  SUBMITTED = 'gp:after-submitted',
  QUANTITY_CHANGE = 'gp:quantity-change',
  IN_STOCK = 'gp:in-stock',
  OUT_OF_STOCK = 'gp:out-of-stock',
  REMAINING_TIME = 'gp:countdown-timer',
  ITEM_CLICK = 'gp:item-click', // Item List, Advanced List
  ITEM_HOVERED = 'gp:item-hovered',
  EXPAND_ITEM = 'gp:change-expanded-item',
  COMPARE_PRICE_CHANGE = 'gp:compare-price-change',
  PRICE_CHANGE = 'gp:price-change',
  APPEAR = 'gp:appear',
  EMPTY = '',
  POPUP_OPEN = 'gp:popup-open',
  POPUP_CLOSE = 'gp:popup-close',
  FINISH_LOADING = 'finish-loading',
  SCROLL_UP = 'gp:scroll-up',
  SCROLL_DOWN = 'gp:scroll-down',
  TAB_ACTIVE = 'gp:tab-active',
  SELECT_SLIDE = 'gp:select-slide',
}

export enum RollbackEvent {
  SECOND_CLICK = 'gp:rollback:click',
  MOUSE_LEAVE = 'mouseleave',
}

export type OptionSelect = {
  id: TriggerEvent | string;
  name: string;
  value?: ControlType;
  subTitle?: string;
  des?: string;
  icon?: string;
  tooltip?: string;
  svgIcon?: string;
  note?: string;
  additionData?: Record<string, string | boolean>;
  default?: any;
  isTriggerForChildren?: boolean;
};

export const targetEventGeneral: OptionSelect[] = [
  {
    value: ControlType.CLICK,
    name: 'Click on element',
    id: TriggerEvent.CLICK,
    svgIcon: 'click-trigger',
    default: {
      rollbackEvent: {
        value: 'none',
        mappingKeys: ['rollbackEvent', 'event'],
      },
    },
  },
  {
    value: ControlType.MOUSE_ENTER,
    name: 'Hover element',
    id: TriggerEvent.MOUSE_ENTER,
    svgIcon: 'hover-trigger',
    default: {
      rollbackEvent: {
        value: 'none',
        mappingKeys: ['rollbackEvent', 'event'],
      },
    },
  },
];

export const triggerEventEntirePage: OptionSelect[] = [
  {
    value: ControlType.SCROLL_UP,
    name: 'Scroll up',
    id: TriggerEvent.SCROLL_UP,
    svgIcon: 'scroll-up-trigger',
    default: {
      condition: {
        value: {},
        mappingKeys: ['condition'],
      },
      rollbackEvent: {
        value: 'none',
        mappingKeys: ['rollbackEvent', 'event'],
      },
    },
  },
  {
    value: ControlType.SCROLL_DOWN,
    name: 'Scroll down',
    id: TriggerEvent.SCROLL_DOWN,
    svgIcon: 'scroll-down-trigger',
    default: {
      condition: {
        value: {},
        mappingKeys: ['condition'],
      },
      rollbackEvent: {
        value: 'none',
        mappingKeys: ['rollbackEvent', 'event'],
      },
    },
  },
  {
    value: ControlType.FINISH_LOADING,
    name: 'Finish loading',
    id: TriggerEvent.FINISH_LOADING,
    default: {
      data: {
        value: '0',
        mappingKeys: ['condition', 'data'],
      },
      type: {
        value: 'delay',
        mappingKeys: ['condition', 'type'],
      },
      rollbackEvent: {
        value: 'none',
        mappingKeys: ['rollbackEvent', 'event'],
      },
    },
    svgIcon: 'network-trigger',
  },
];

export const triggerEventVariant: OptionSelect[] = [
  {
    name: 'Click on option',
    id: TriggerEvent.CLICK,
    svgIcon: 'click-trigger',
    value: ControlType.VARIANT_CLICKED,
    isTriggerForChildren: true,
    default: {
      stock: {
        value: 'in-stock',
        mappingKeys: ['condition', 'data', 'stock'],
      },
      optionName: {
        value: 'none',
        mappingKeys: ['condition', 'data', 'optionName'],
      },
    },
  },
  {
    name: 'Hover option',
    id: TriggerEvent.MOUSE_ENTER,
    svgIcon: 'hover-trigger',
    value: ControlType.VARIANT_HOVERED,
    isTriggerForChildren: true,
    default: {
      rollbackEvent: {
        value: 'none',
        mappingKeys: ['rollbackEvent', 'event'],
      },
    },
  },
];

export const triggerEventItem: OptionSelect[] = [
  {
    id: TriggerEvent.CLICK,
    additionData: {
      isTriggerListenerForChildren: true,
    },
    isTriggerForChildren: true,
    name: 'Click on item',
    value: ControlType.ITEM_CLICK,
    svgIcon: 'click-trigger',
    default: {
      doubleClick: {
        value: 'none',
        mappingKeys: ['condition', 'metaData', 'doubleClick'],
      },
    },
  },
  {
    id: TriggerEvent.MOUSE_ENTER,
    additionData: {
      isTriggerListenerForChildren: true,
    },
    isTriggerForChildren: true,
    name: 'Hover item',
    value: ControlType.ITEM_HOVERED,
    svgIcon: 'hover-trigger',
    default: {
      rollbackEvent: {
        value: 'none',
        mappingKeys: ['rollbackEvent', 'event'],
      },
    },
  },
];

export const triggerEventPrice: OptionSelect[] = [
  {
    value: ControlType.PRICE_CHANGE,
    name: 'Price is',
    id: TriggerEvent.PRICE_CHANGE,
    svgIcon: 'click-trigger',
    default: {
      type: {
        value: 'greater_or_equal',
        mappingKeys: ['condition', 'type'],
      },
      data: {
        value: '1',
        mappingKeys: ['condition', 'data'],
      },
    },
  },
  {
    value: ControlType.COMPARE_PRICE_CHANGE,
    name: 'Compare price is',
    id: TriggerEvent.COMPARE_PRICE_CHANGE,
    svgIcon: 'click-trigger',
    default: {
      type: {
        value: 'greater_or_equal',
        mappingKeys: ['condition', 'type'],
      },
      data: {
        value: '1',
        mappingKeys: ['condition', 'data'],
      },
    },
  },
];

export const triggerEventPopup: OptionSelect[] = [
  {
    value: ControlType.POPUP_OPEN,
    name: 'Open popup',
    id: TriggerEvent.POPUP_OPEN,
    svgIcon: 'scroll-up-trigger',
    default: {
      data: {
        value: '0',
        mappingKeys: ['condition', 'data'],
      },
      type: {
        value: 'delay',
        mappingKeys: ['condition', 'type'],
      },
    },
  },
  {
    value: ControlType.POPUP_CLOSE,
    name: 'Close popup',
    id: TriggerEvent.POPUP_CLOSE,
    svgIcon: 'scroll-down-trigger',
    default: {
      data: {
        value: '0',
        mappingKeys: ['condition', 'data'],
      },
      type: {
        value: 'delay',
        mappingKeys: ['condition', 'type'],
      },
    },
  },
];

export const generalElementTags = [
  'Section',
  'Row',
  'Heading',
  'Button',
  'Text',
  'DynamicCheckout',
  'Image',
  'Video',
  'HeroBanner',
  'ImageComparison',
  'Icon',
  'IconList',
  'IconListHoz',
  'ProductImages',
]; // and more

export const triggerEventMapping: Record<string, OptionSelect[]> = {
  general: targetEventGeneral,
  IconList: [...triggerEventItem, ...targetEventGeneral],
  IconListV2: [...triggerEventItem, ...targetEventGeneral],
  ProductQuantity: [
    {
      value: ControlType.QUANTITY_CHANGE,
      name: 'Quantity is',
      id: TriggerEvent.QUANTITY_CHANGE,
      svgIcon: 'click-trigger',
      default: {
        type: {
          value: 'greater_or_equal',
          mappingKeys: ['condition', 'type'],
        },
        data: {
          value: '1',
          mappingKeys: ['condition', 'data'],
        },
      },
    },
    ...targetEventGeneral,
  ],
  StockCounter: [
    {
      value: ControlType.IN_STOCK,
      name: 'In stock',
      id: TriggerEvent.STOCK_CHANGE,
      svgIcon: 'click-trigger',
      default: {
        type: {
          value: 'greater_or_equal',
          mappingKeys: ['condition', 'type'],
        },
        data: {
          value: '1',
          mappingKeys: ['condition', 'data'],
        },
      },
    },
    {
      value: ControlType.OUT_OF_STOCK,
      name: 'Out of stock',
      id: TriggerEvent.OUT_OF_STOCK,
      svgIcon: 'click-trigger',
      default: {
        type: {
          value: 'less_or_equal',
          mappingKeys: ['condition', 'type'],
        },
        data: {
          value: '0',
          mappingKeys: ['condition', 'data'],
        },
      },
    },
    ...targetEventGeneral,
  ],
  Countdown: [
    {
      value: ControlType.REMAINING_TIME,
      name: 'Remaining time is',
      id: TriggerEvent.REMAINING_TIME,
      svgIcon: 'click-trigger',
      default: {
        type: {
          value: 'equal',
          mappingKeys: ['condition', 'type'],
        },
        data: {
          value: '1800',
          mappingKeys: ['condition', 'data'],
        },
        countdown: {
          value: {
            day: '0',
            hour: '0',
            minute: '30',
            second: '0',
          },
          mappingKeys: ['condition', 'metaData', 'countdown'],
        },
      },
    },
    ...targetEventGeneral,
  ],
  ProductPrice: [...triggerEventPrice, ...targetEventGeneral],
  ProductVariants: [...triggerEventVariant, ...targetEventGeneral],
  ProductList: [
    ...targetEventGeneral,
    {
      id: TriggerEvent.MOUSE_ENTER,
      additionData: {
        isTriggerListenerForChildren: true,
      },
      subTitle: 'Hover a product',
      name: 'Hover a product',
      value: ControlType.ITEM_HOVERED,
      svgIcon: 'hover-trigger',
      default: {
        rollbackEvent: {
          value: 'none',
          mappingKeys: ['rollbackEvent', 'event'],
        },
      },
    },
  ],
  Dialog: triggerEventPopup,
  Tabs: [...targetEventGeneral],
  Accordion: [...targetEventGeneral],
  Carousel: [...targetEventGeneral],
  ProductButton: [...targetEventGeneral],
};

export const targetEventCommon: TargetEventOption[] = [
  {
    id: 'gp:show-or-hide',
    value: 'gp:show-or-hide',
    name: 'Show/Hide',
    hasCondition: true,
    defaultCondition: {
      type: 'equal',
      data: 'appearByTrigger',
    },
  },
  {
    id: 'gp:scroll-to',
    value: 'gp:scroll-to',
    name: 'Scroll to',
    hasCondition: true,
    defaultCondition: {
      data: '30px',
    },
  },
];

const targetEventChangeBgColor: TargetEventOption = {
  id: 'gp:change-color-shape',
  value: 'gp:change-color-shape',
  name: 'Background & Shape',
  hasCondition: true,
};

const targetEventChangeImageSlide: TargetEventOption = {
  id: 'gp:change-image-step',
  value: 'gp:change-image-step',
  name: 'Change feature image',
  hasCondition: true,
  defaultCondition: {
    data: 1,
  },
};

const targetEventChangeSlide: TargetEventOption[] = [
  {
    id: 'gp:change-next-slide',
    value: 'gp:change-next-slide',
    name: 'Next slide',
    hasCondition: false,
  },
  {
    id: 'gp:change-previous-slide',
    value: 'gp:change-previous-slide',
    name: 'Previous slide',
    hasCondition: false,
  },
];

const targetEventChangeContent: TargetEventOption = {
  id: 'gp:change-content',
  value: 'gp:change-content',
  name: 'Change content',
  hasCondition: true,
};

const targetEventChangeTextStyle: TargetEventOption = {
  id: 'gp:change-text-style',
  value: 'gp:change-text-style',
  name: 'Text style',
  hasCondition: true,
};

const targetEventChangeImage: TargetEventOption = {
  id: 'gp:change-image',
  value: 'gp:change-image',
  name: 'Change image',
  hasCondition: true,
};

const targetEventChangeBanner: TargetEventOption = {
  id: 'gp:change-banner',
  value: 'gp:change-banner',
  name: 'Change banner',
  hasCondition: true,
};

const targetEventChangeIcon: TargetEventOption = {
  id: 'gp:change-icon-style',
  value: 'gp:change-color-shape',
  name: 'Icon style',
  hasCondition: true,
};

const targetEventChangeNextPage: TargetEventOption = {
  id: 'gp:change-next-page',
  value: 'gp:change-next-page',
  name: 'Go to page',
  hasCondition: true,
};

const targetEventCopyCoupon: TargetEventOption = {
  id: 'gp:copy-text',
  value: 'gp:copy-text',
  name: 'Copy coupon code',
  hasCondition: true,
};

export const targetEventMapping: Record<string, TargetEventOption[]> = {
  general: targetEventCommon,
  Section: [targetEventChangeBgColor, ...targetEventCommon],
  Row: [targetEventChangeBgColor, ...targetEventCommon],
  Heading: [targetEventChangeTextStyle, targetEventChangeContent, ...targetEventCommon],
  Text: [targetEventChangeTextStyle, targetEventChangeContent, ...targetEventCommon],
  Button: [targetEventChangeBgColor, targetEventChangeTextStyle, targetEventChangeContent, ...targetEventCommon],
  Image: [targetEventChangeImage, ...targetEventCommon],
  HeroBanner: [targetEventChangeBanner, ...targetEventCommon],
  Icon: [targetEventChangeIcon, ...targetEventCommon],
  SubmitButton: [targetEventChangeBgColor, targetEventChangeContent, targetEventChangeTextStyle, ...targetEventCommon],
  DynamicCheckout: [
    targetEventChangeBgColor,
    targetEventChangeContent,
    targetEventChangeTextStyle,
    ...targetEventCommon,
  ],
  ProductButton: [targetEventChangeBgColor, targetEventChangeContent, targetEventChangeTextStyle, ...targetEventCommon],
  ProductTitle: [targetEventChangeTextStyle, ...targetEventCommon],
  ProductDescription: [targetEventChangeTextStyle, ...targetEventCommon],
  ProductPrice: [targetEventChangeTextStyle, ...targetEventCommon],
  ProductViewMore: [
    targetEventChangeBgColor,
    targetEventChangeContent,
    targetEventChangeTextStyle,
    ...targetEventCommon,
  ],
  Sticky: [
    {
      id: 'gp:show-or-hide',
      value: 'gp:show-or-hide',
      name: 'Show/Hide',
      hasCondition: true,
      defaultCondition: {
        type: 'equal',
        data: 'appearByTrigger',
      },
    },
  ],
  ProductImagesV2: [targetEventChangeImageSlide, ...targetEventChangeSlide, ...targetEventCommon],
  Dialog: [
    {
      id: 'gp:toggle-popup-close',
      value: 'gp:toggle-popup-close',
      name: 'Close popup',
    },
    {
      id: 'gp:toggle-popup-open',
      value: 'gp:toggle-popup-open',
      name: 'Open popup',
    },
  ],
  Accordion: [
    {
      id: 'gp:change-expanded-item',
      value: 'gp:change-expanded-item',
      name: 'Expand item',
      hasCondition: true,
    },
    ...targetEventCommon,
  ],
  Carousel: [...targetEventChangeSlide, ...targetEventCommon],
};

export const ANIMATION_TRIGGER_TAB_PROPS: Array<{ id: AnimationTriggerType; label: string }> = [
  {
    id: AnimationTriggerType.AppearByTrigger,
    label: 'Show',
  },
  {
    id: AnimationTriggerType.Hidden,
    label: 'Hide',
  },
];

export type KeyOfInteractionEvent = { [key in keyof typeof InteractionTriggerEvent]: string };

export const interactionRollbackMapping: KeyOfInteractionEvent = {
  mouseenter: RollbackEvent.MOUSE_LEAVE,
  'gp:appear': 'gp:rollback:appear',
  'gp:item-selected': 'gp:rollback:item-selected',
  'gp:item-unselected': 'gp:rollback:item-unselected',
  'gp:item-hovered': '',
  'gp:item-click': '',
  'gp:after-submit': 'gp:rollback:after-submit',
  click: RollbackEvent.SECOND_CLICK,
  active: '',
  'gp:distance': '',
  'gp:delay': '',
  'gp:variant-hovered': '',
  'gp:variant-clicked': '',
  'gp:variant-selected': '',
  'gp:quantity-change': 'gp:rollback:quantity-change',
  'gp:after-checked-value': 'gp:rollback:after-checked-value',
  'gp:after-add-to-cart': 'gp:rollback:after-add-to-cart',
  'gp:popup-open': 'gp:rollback:popup-open',
  'gp:popup-close': 'gp:rollback:popup-close',
  'gp:scroll-up': 'gp:scroll-down',
  'gp:scroll-down': 'gp:scroll-up',
  'gp:compare-price-change': 'gp:rollback:compare-price-change',
  'gp:price-change': 'gp:rollback:price-change',
  'gp:stock-change': 'gp:rollback:stock-change',
  'gp:out-of-stock': 'gp:rollback:out-of-stock',
};

export const interactionLocalStorageKey = {
  offPopoverInteractionTimes: 'off-popover-interaction-times',
  isOffPopoverInteractionForever: 'is-off-popover-interaction-forever',
};

export const customSubTitleMapping: Record<string, string> = {
  [ControlType.PRICE_CHANGE]: 'Check price',
  [ControlType.COMPARE_PRICE_CHANGE]: 'Check compare price',
  [ControlType.QUANTITY_CHANGE]: 'Check quantity',
  [ControlType.ITEM_SELECTED]: 'Click on ${name}',
  [ControlType.VARIANT_HOVERED]: 'Hover ${name}',
  [ControlType.VARIANT_CLICKED]: 'Click on ${name}',
  [ControlType.ITEM_CLICK]: 'Click on ${name}',
  [ControlType.ITEM_HOVERED]: 'Hover ${name}',
  [ControlType.SUBMITTED]: 'After submitted',
  [ControlType.IN_STOCK]: 'Count remaining stock',
  [ControlType.OUT_OF_STOCK]: 'Count remaining stock',
  [ControlType.REMAINING_TIME]: 'Check remaining time',
  [ControlType.APPEAR]: 'Element appears',
  [ControlType.CLICK]: 'Click on element',
  [ControlType.MOUSE_ENTER]: 'Hover element',
  [ControlType.MOUSE_OVER]: 'Hover element',
  [ControlType.EXPAND_ITEM]: 'Expand ${name}',
  [ControlType.POPUP_OPEN]: 'Open popup',
  [ControlType.POPUP_CLOSE]: 'Close popup',
  [ControlType.SCROLL_UP]: 'Scroll up',
  [ControlType.SCROLL_DOWN]: 'Scroll down',
  [ControlType.FINISH_LOADING]: 'Finish loading',
  [ControlType.TAB_ACTIVE]: 'Active ${name}',
  [ControlType.SELECT_SLIDE]: 'Select ${name}',
};

export const rollbackTooltipMapping = {
  'gp:rollback:appear': 'Check to reverse interaction if trigger disappears. Uncheck to keep the change.',
  [RollbackEvent.MOUSE_LEAVE]: 'Check to reverse interaction on mouse out. Uncheck to keep the change.',
  [RollbackEvent.SECOND_CLICK]:
    'Check to reverse interaction if click again. Uncheck to repeat interaction on each click.',
  'gp:scroll-down': 'Check to reverse interaction when scroll down. Uncheck to keep the change.',
  'gp:scroll-up': 'Check to reverse interaction when scroll up. Uncheck to keep the change.',
};

export const labelRollbackMapping = {
  'gp:scroll-up': 'If scroll down',
  'gp:scroll-down': 'If scroll up',
};

export const DEFAULT_BG_TARGET = {
  backgroundColor: '#000000',
  borderRadius: {
    btrr: '0px',
    bblr: '0px',
    bbrr: '0px',
    btlr: '0px',
    radiusType: 'custom',
  },
  border: {
    border: 'none',
    borderWidth: '1px',
    width: '1px 1px 1px 1px',
    isCustom: true,
    color: '#000000',
  },
  hasShadow: false,
  boxShadow: {
    type: 'custom',
    distance: '4px',
    blur: '12px',
    spread: '0px',
    color: '#000000',
    angle: 90,
  },
};

export const DEFAULT_ICON_TARGET = {
  iconColor: '#000000',
  backgroundColor: '#000000',
  borderRadius: {
    btrr: '0px',
    bblr: '0px',
    bbrr: '0px',
    btlr: '0px',
    radiusType: 'custom',
  },
  border: {
    border: 'none',
    borderWidth: '1px',
    width: '1px 1px 1px 1px',
    isCustom: true,
    color: '#000000',
  },
  hasShadow: false,
  boxShadow: {
    type: 'custom',
    distance: '4px',
    blur: '12px',
    spread: '0px',
    color: '#000000',
    angle: 90,
  },
};

export const ELEMENT_DISABLED_ANIMATION = ['Sticky'];

export const TOGGLE_DISABLED_EVENTS: Record<string, string[]> = {
  'gp:toggle-popup-close': ['gp:toggle-popup-open'],
  'gp:toggle-popup-open': ['gp:toggle-popup-close'],
  'gp:change-next-slide': ['gp:change-previous-slide', 'gp:change-image-step'],
  'gp:change-previous-slide': ['gp:change-next-slide', 'gp:change-image-step'],
  'gp:change-image-step': ['gp:change-next-slide', 'gp:change-previous-slide'],
};
