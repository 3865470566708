import { emitActiveComponent, waitForElement } from '@/modules/editor/modules/preview/utils/emitToIframe';
import { sentryCaptureException } from '@/modules/editor/modules/common/use-cases/sentry';

const getIframeDocument = () => {
  const $iframe = document.body.querySelector<HTMLIFrameElement>('.iframe');
  if (!$iframe) return null;
  return $iframe.contentDocument || ($iframe.contentWindow as any).document;
};

export const handleActiveComponent = (
  data: {
    componentUid: string;
    sectionId: string;
    productId?: string;
    articleId?: string;
    marqueeKey?: string;
  } | null,
  scrollToActiveUid: (uid: string) => void,
  callback?: () => void,
) => {
  const { componentUid, sectionId } = data || {};

  if (!componentUid || !sectionId) return;
  scrollToActiveUid(sectionId);

  const $iframeDoc = getIframeDocument();
  if (!$iframeDoc) return;

  const selector = `[data-uid="${componentUid}"]`;
  waitForElement($iframeDoc, selector, () => {
    const $component = $iframeDoc.querySelector(selector);
    if ($component) {
      scrollToActiveUid(componentUid);
      emitActiveComponent(data, callback);
    }
  });
};

export const logInteractionError = (funcName: string, message: string, data: object) => {
  sentryCaptureException(funcName, message, data, {
    level: 'error',
    tag: {
      key: 'Modules',
      value: 'Interaction',
    },
  });
};
